import React from "react";
import "./HeroSection.module.css"; // You can style your hero section in this file
import { IconContext } from "react-icons";
import { FaDownload, FaArrowRight } from "react-icons/fa";
// import GooglePlayBadge from "../../assets/images/google-play-badge.png";
import img from "../../assets/images/Screenshot1.png";
import img2 from "../../assets/images/Screenshot2.png";
import { ProductsData } from "../../data/ProductsData";
import styles from "./HeroSection.module.css";
import buttonstyles from "../../constants/Global.module.css";

interface HeroSectionProps {
  title: string;
  subtitle: string;
  route?: string;
  body?: string;
  buttonText?: string;
  buttonLink?: string;
  buttonVisible?: boolean;
}

const HeroSection = ({
  route,
  title,
  subtitle,
  body,
  buttonText,
  buttonVisible,
}: HeroSectionProps) => {
  const product = ProductsData.find((product) => product.id === "jengu");
  if (!product) {
  }
  return (
    <section
      className={route === "Home" ? styles.heroSection : styles.heroSectionMini}
    >
      <div className={styles.heroContainer}>
        {route === "Home" && (
          <div className={styles.heroImageContainer}>
            <img
              src={img}
              alt="Jengu Screenshot"
              className={styles.heroImageLeft}
            />
            <img
              src={img2}
              alt="Jengu Screenshot"
              className={styles.heroImage}
            />
          </div>
        )}
        <div className={styles.heroContent}>
          <h1 className={styles.heroTitle}>{title}</h1>
          <p className={styles.heroSubtitle}>{subtitle}</p>
          {body && <p className={styles.heroBody}>{body}</p>}
          {buttonVisible && (
            <div className={styles.buttonsContainer}>
              {/* <div>
              <a href={buttonLink} className="play-button">
                <img
                  src={GooglePlayBadge}
                  alt="Google Play"
                  className="google-badge"
                />
              </a>
            </div> */}
              <IconContext.Provider
                value={{ style: { verticalAlign: "middle" } }}
              >
                <a
                  href={product?.downloadLink}
                  className={`${buttonstyles.button} ${buttonstyles.buttonAccented}`}
                  style={{ marginRight: "14px" }}
                >
                  <FaDownload className={buttonstyles.buttonIcon} />
                  Download
                </a>
                <a
                  href="/product/jengu"
                  className={`${buttonstyles.button} ${buttonstyles.buttonPrimary}`}
                >
                  <FaArrowRight className={buttonstyles.buttonIcon} />
                  {buttonText}
                </a>
              </IconContext.Provider>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
