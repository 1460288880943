import { SetStateAction, useEffect, useState } from "react";
import useNavigationContext from "../../contexts/NavigationContext";
import axios from "axios";
import DropdownList from "../../components/DropdownList/DropdownList";
import Body from "../../layout/Body/Body";
import styles from "./Support.module.css";

const Support = () => {
  const { setSideDrawerIsOpen } = useNavigationContext();
  useEffect(() => {
    setSideDrawerIsOpen(false);
  }, [setSideDrawerIsOpen]);

  const subjectOptions = [
    { name: "General Enquiry", value: "general", default: true },
    { name: "Technical Support", value: "technical" },
    { name: "Feedback and Suggestions", value: "feedback" },
  ];

  const [sent, setSent] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [nameEntered, setNameEntered] = useState(false);
  const [emailEntered, setEmailEntered] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [messageEntered, setMessageEntered] = useState(false);

  const onChangeName = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setName(event.target.value);
    setNameEntered(event.target.value.length > 0);
  };
  const onChangeEmail = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setEmail(event.target.value);
    setEmailEntered(event.target.value.length > 0);
    setEmailValid(
      event.target.value.toString().includes("@") &&
        event.target.value.toString().includes("."),
    );
  };
  const onChangeMessage = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setMessage(event.target.value);
    setMessageEntered(event.target.value.length > 0);
  };
  const onSubmitForm = () => {
    setSubmit(true);
    if (nameEntered && emailEntered && emailValid && messageEntered) {
      sendEmail().then(() => {});
    }
  };
  const getSubjectTidy = () => {
    if (subject === "general") {
      return "General Enquiry";
    } else if (subject === "technical") {
      return "Technical Support";
    } else if (subject === "feedback") {
      return "Feedback and Suggestions";
    }
    return "General Inquiry";
  };
  const sendEmail = async () => {
    setSent(true);
    try {
      await axios.post(`https://server.orioncyber.co.uk/send-email`, {
        name: name,
        email: email,
        subject: getSubjectTidy(),
        message: message.replace(/\r\n|\r|\n/g, "<br />"),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const tagline = (
    <p>
      At Orion Cyber, we're committed to providing exceptional support to ensure
      you have a seamless experience with our digital solutions. Whether you
      have questions, encounter technical issues, or need assistance with our
      services, our dedicated support team is here to help.
    </p>
  );

  const mainBody = (
    <>
      <h3>Frequently Asked Questions (FAQs)</h3>
      <p>
        Before reaching out to our support team, you may find the answers to
        your questions in our FAQ section. We've compiled a list of frequently
        asked questions to provide you with quick answers and solutions to
        common inquiries.
      </p>
      <h3>How Can We Assist You?</h3>
      <ul>
        <li>
          <strong>General Inquiries:</strong> Have a question about our services
          or need more information? Feel free to reach out to us. Our
          knowledgeable team is ready to provide you with the answers you need.
        </li>
        <li>
          <strong>Technical Support:</strong> Encountering technical issues with
          one of our products? Don't worry, we've got you covered. Our technical
          support team is available to troubleshoot and resolve any issues you
          may encounter, ensuring you can continue to use our solutions with
          confidence.
        </li>
        <li>
          <strong>Feedback and Suggestions:</strong> Your feedback is invaluable
          to us. We welcome any suggestions or ideas you may have for improving
          our products or services. Your input helps us continuously enhance our
          offerings to better meet your needs.
        </li>
      </ul>
      <h3>Contact Us</h3>
      <p>
        Getting in touch with our support team is easy, just fill out the form
        below and we'll get back to you as soon as possible.
      </p>
      {/*<span style={{ color: "lime" }}>CONTACT FORM</span>*/}

      {sent ? (
        <div className={styles.form}>
          <h3 style={{ color: "white" }}>Message Sent </h3>
          <p style={{ marginTop: 0 }}>
            We will get back to you as soon as possible.
          </p>
        </div>
      ) : (
        <form onSubmit={onSubmitForm} className={styles.form}>
          <div className={styles.formGroup}>
            <label htmlFor="name">Full Name</label>
            <input
              name="name"
              type="text"
              value={name}
              onChange={onChangeName.bind(this)}
              required
            />
            {submit && !nameEntered ? (
              <div style={{ color: "lime" }}>Please enter your name</div>
            ) : (
              ""
            )}
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="eadd">Email</label>
            <input
              name="eadd"
              type="email"
              value={email}
              onChange={onChangeEmail.bind(this)}
              required
              pattern=".+@.+\..+"
            />
            {submit && !emailEntered ? (
              <div style={{ color: "lime" }}>Please enter your email</div>
            ) : (
              ""
            )}
            {submit && emailEntered && !emailValid ? (
              <div style={{ color: "lime" }}>Please enter a valid email</div>
            ) : (
              ""
            )}
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="subject">Subject</label>
            <DropdownList
              options={subjectOptions}
              setSelected={setSubject}
              selected={subject}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="message">Message</label>
            <textarea
              name="message"
              value={message}
              onChange={onChangeMessage.bind(this)}
              rows={6}
              required
            />
            {submit && !messageEntered ? (
              <div style={{ color: "lime" }}>Please enter a message</div>
            ) : (
              ""
            )}
          </div>
          <a
            href="javascript:{}"
            role="button"
            onClick={onSubmitForm}
            className={styles.formButton}
          >
            Send Message
          </a>
        </form>
      )}
    </>
  );

  return (
    <>
      <Body
        mainBody={mainBody}
        headerActiveLink={"Support"}
        tagline={tagline}
        isFullWidth={false}
      />
    </>
  );
};

export default Support;
