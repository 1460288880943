import img from "../../assets/images/jengu-logo.png";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import { FaArrowRight, FaDownload } from "react-icons/fa6";
import React from "react";
import styles from "./ProductPanel.module.css";
import globalStyles from "../../constants/Global.module.css";

type ProductPanelProps = {
  title: string;
  description: string;
  downloadLink: string;
  productLink: string;
};

const ProductPanel = ({
  title,
  description,
  productLink,
  downloadLink,
}: ProductPanelProps) => {
  return (
    <div className={styles.panelContainer}>
      <div
        className={styles.productPanel}
        style={{ marginLeft: "0px", marginRight: "0px" }}
      >
        <div className={styles.productImageContainer}>
          <img src={img} alt="Jengu Logo" className={styles.productImage} />
        </div>
        <div>
          <h2>{title}</h2>
          <p>{description}</p>
          <div className={styles.productActionsButtonsContainer}>
            <IconContext.Provider
              value={{ style: { verticalAlign: "middle" } }}
            >
              <Link
                to={downloadLink}
                className={`${globalStyles.button} ${styles.productActionButton} ${globalStyles.buttonAccented}`}
              >
                <FaDownload className={globalStyles.buttonIcon} />
                Download
              </Link>
              <Link
                to={productLink}
                className={`${globalStyles.button} ${globalStyles.buttonPrimary}`}
              >
                <FaArrowRight className={globalStyles.buttonIcon} />
                Learn More
              </Link>
            </IconContext.Provider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPanel;
