import React from "react";
import styles from "./Body.module.css";
import Header from "../Header/Header";

type FullWidthProps = {
  mainBody: React.ReactNode;
  headerActiveLink: string;
  smallBody?: React.ReactNode;
  tagline?: React.ReactNode;
  isFullWidth?: boolean;
};

const Body = ({
  mainBody,
  smallBody,
  headerActiveLink,
  isFullWidth = true,
  tagline,
}: FullWidthProps) => {
  return (
    <div className={styles.appContainer}>
      <Header activeLink={headerActiveLink} />
      <div className={styles.bodyContainer}>
        <div className={styles.bodyContent}>
          <div className={styles.tagline}>{tagline}</div>
          {isFullWidth ? (
            mainBody
          ) : (
            <div className={styles.bodySplit}>
              <div className={styles.bodyLeft}>{mainBody}</div>
              <div className={styles.bodyRight}>{smallBody}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Body;
