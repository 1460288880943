import styles from "./DropdownList.module.css";
import {useEffect, useState} from "react";

type DropdownListProps = {
  options: {name: string, value: string, default?: boolean}[];
  selected: string;
  setSelected: (value: string) => void;
}

const DropdownList = ({options, selected, setSelected}: DropdownListProps) => {
  const [open, setOpen] = useState(false);
  // const [selected, setSelected] = useState("");

  const handleOpen = () => {
    setOpen(!open);
  };
  const handleSelect = (option: {name: string, value: string}) => {
    setSelected(option.value);
    setOpen(false);
  }

  useEffect(() => {
    const defaultOption = options.find(option => option.default);
    if (selected === "" && defaultOption) {
      setSelected(defaultOption.value);
    }
  }, []);

  const getOptionNameFromValue = () => {
    const option = options.find(option => option.value === selected);
    return option ? option.name : "";
  }

  return (
    <div className={styles.dropdownContainer}>
      <a href="javascript:{}" role="button" onClick={handleOpen} className={styles.dropdown}>
        <div>{getOptionNameFromValue()}</div>
        <div><i className={`fa fa-xl ${open ? "fa-chevron-up" : "fa-chevron-down"}`}/></div>
      </a>
      {open ? (
        <ul className={styles.options}>
          {options.map((option) => (
            <li className={styles.optionContainer} key={option.value}>
              <a href="javascript:{}" role="button" onClick={handleSelect.bind(this, option)} className={styles.option}>{option.name}</a>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}

export default DropdownList;