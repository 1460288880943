export const HeroData = [
  {
    route: "Home",
    title: "Your Ultimate Fasting Companion",
    subtitle: `Unlock the power of fasting with Jengu`,
    body: `The revolutionary app designed to transform your fasting journey into a seamless and personalised experience. Say goodbye to generic fasting apps and embrace a new era of customisation, convenience, and scientific precision.`,
    buttonText: "Learn More",
    buttonLink: "/about",
    buttonVisible: true,
  },
  {
    route: "About",
    title: "About Us",
    subtitle: "We are a team of developers",
    body: "",
    buttonText: "Our Services",
    buttonLink: "/services",
    buttonVisible: false,
  },
  {
    route: "Articles",
    title: "Articles",
    subtitle: "Read our latest articles",
    body: "",
    buttonText: "View Articles",
    buttonLink: "/articles",
    buttonVisible: false,
  },
  {
    route: "Products",
    title: "Our Products",
    subtitle: "Completed projects available now",
    body: "",
    buttonText: "View Products",
    buttonLink: "/products",
    buttonVisible: false,
  },
  {
    route: "Services",
    title: "Our Services",
    subtitle: "We offer a wide range of services",
    body: "",
    buttonText: "View Services",
    buttonLink: "/services",
    buttonVisible: false,
  },
  {
    route: "Support",
    title: "Support",
    subtitle: "We are here to help you",
    body: "",
    buttonText: "Contact Us",
    buttonLink: "/support",
    buttonVisible: false,
  },
];
