import React, { useEffect } from "react";
import useNavigationContext from "../../contexts/NavigationContext";
import { ProductsData } from "../../data/ProductsData";
import Body from "../../layout/Body/Body";
import styles from "./Products.module.css";
import ProductPanel from "../../components/ProductPanel/ProductPanel";

const Products = () => {
  const { setSideDrawerIsOpen } = useNavigationContext();
  useEffect(() => {
    setSideDrawerIsOpen(false);
  }, [setSideDrawerIsOpen]);

  const product = ProductsData.find((product) => product.id === "jengu");
  const getJenguDownloadLink = () => {
    if (product) {
      return product.downloadLink;
    } else {
      return "https://play.google.com/";
    }
  };

  const tagline = (
    <p>
      Welcome to our Products page, where your journey to a more organized and
      healthier life begins.
    </p>
  );

  const mainBody = (
    <>
      <p>
        We offer a selection of innovative apps and tools, designed to not only
        simplify your daily routines but also optimise your health and
        well-being.
      </p>
      <p className={styles.spaceAfter}>
        Each of our products are crafted to provide real solutions, helping you
        save time, reduce stress, and take control of your personal and health
        goals. By integrating our cutting-edge technology into your life, you’ll
        unlock new levels of productivity and wellness. Take the next step
        toward a better you, and explore the tools that can make a lasting
        impact today.
      </p>
      <ProductPanel
        title="Jengu Fasting Tracker"
        description="Unlock the power of fasting with Jengu, the revolutionary app
            designed to transform your fasting journey into a seamless and
            personalised experience. Say goodbye to generic fasting apps and
            embrace a new era of customisation, convenience, and scientific
            precision."
        productLink={"/product/jengu"}
        downloadLink={getJenguDownloadLink()}
      />
    </>
  );

  return (
    <>
      <Body mainBody={mainBody} headerActiveLink="Products" tagline={tagline} />
    </>
  );
};

export default Products;
