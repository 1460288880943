import React, { useEffect } from "react";
import useNavigationContext from "../../contexts/NavigationContext";
import { ArticlesData } from "../../data/ArticlesData";
import { Link, useParams } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa6";
import { IconContext } from "react-icons";
import styles from "./Articles.module.css";
import Body from "../../layout/Body/Body";

const Articles = () => {
  const { setSideDrawerIsOpen, isMobile } = useNavigationContext();
  useEffect(() => {
    setSideDrawerIsOpen(false);
  }, [setSideDrawerIsOpen]);

  const params = useParams();
  // console.log("params: ", params.articleId);

  const renderAllArticles = () => {
    return (
      <div className={styles.articles}>
        {ArticlesData.map((article) => (
          <div key={article.articleId} className={styles.article}>
            <Link to={`/articles/${article.articleId}`}>
              <h2>{article.title}</h2>
            </Link>
            <h3>{article.subtitle}</h3>
            <p>{article.preview}</p>
          </div>
        ))}
      </div>
    );
  };

  const renderSingleArticle = () => {
    const article = ArticlesData.find(
      (article) => article.articleId === params.articleId,
    );
    if (!article) {
      return <div>Article not found</div>;
    }
    return (
      <>
        <div>
          {isMobile ? renderArticleContents() : null}
          <div>
            <div className={styles.articleSingle}>
              <div dangerouslySetInnerHTML={{ __html: article.body }} />
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderArticleContents = () => {
    const article = ArticlesData.find(
      (article) => article.articleId === params.articleId,
    );
    if (!article) {
      return <div>Article not found</div>;
    }
    return (
      <div style={{ position: !isMobile ? "sticky" : "inherit", top: "20px" }}>
        <h2>Contents</h2>
        <ul style={{ listStyle: "none", paddingLeft: "0" }}>
          {article.contents.map(
            (content: { id: string; title: string }, index: number) => (
              <li key={index}>
                <a href={`#${content.id}`}>{content.title}</a>
              </li>
            ),
          )}
        </ul>
      </div>
    );
  };

  const tagline = (
    <Link
      to="/articles"
      className={styles.btnBack}
      style={!isMobile ? { width: "160px" } : {}}
    >
      <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
        <FaAngleLeft size="22" />
      </IconContext.Provider>
      &nbsp;All Articles
    </Link>
  );

  return (
    <>
      <Body
        mainBody={
          params.articleId ? renderSingleArticle() : renderAllArticles()
        }
        smallBody={renderArticleContents()}
        headerActiveLink="Articles"
        isFullWidth={isMobile || !params.articleId}
        tagline={params.articleId && tagline}
      />
    </>
  );
};

export default Articles;
