import React, { useEffect } from "react";
import useNavigationContext from "../../contexts/NavigationContext";
import Body from "../../layout/Body/Body";
import Panel from "../../components/Panel/Panel";
import styles from "./Home.module.css";

const Home = () => {
  const { setSideDrawerIsOpen } = useNavigationContext();
  useEffect(() => {
    setSideDrawerIsOpen(false);
  }, [setSideDrawerIsOpen]);

  const tagline = (
    <p>
      At Orion Cyber, we offer a range of digital solutions designed to help you
      achieve your goals and stand out in the digital landscape. From mobile
      applications to websites and graphic design services, we have the
      expertise and creativity to bring your ideas to life.
    </p>
  );

  const mainBody = (
    <div className={styles.panelContainer}>
      <Panel>
        <h3>Mobile Applications</h3>
        <p>
          Our mobile applications are designed to deliver exceptional user
          experiences and drive engagement. Whether you need an app for iOS or
          Android, we can create custom solutions that meet your unique
          requirements.
        </p>
      </Panel>
      <Panel>
        <h3>Websites</h3>
        <p>
          A professional website is essential for establishing your online
          presence and reaching your target audience. We offer custom website
          design and development services to help you create a site that
          reflects your brand and drives results.
        </p>
      </Panel>
      <Panel>
        <h3>Graphic Design</h3>
        <p>
          Stand out from the competition with eye-catching graphic design
          services from Orion Cyber. From logos and branding to marketing
          materials and social media graphics, we can help you make a lasting
          impression on your audience.
        </p>
      </Panel>
    </div>
  );

  return (
    <>
      <Body mainBody={mainBody} headerActiveLink="Home" tagline={tagline} />
    </>
  );
};

export default Home;
