import { Link } from "react-router-dom";
import useNavigationContext from "../../contexts/NavigationContext";
import { useEffect } from "react";
import Body from "../../layout/Body/Body";
import styles from "./About.module.css";

const About = () => {
  const { setSideDrawerIsOpen } = useNavigationContext();
  useEffect(() => {
    setSideDrawerIsOpen(false);
  }, [setSideDrawerIsOpen]);

  const tagline = (
    <p>
      Welcome to Orion Cyber, your premier destination for cutting-edge digital
      solutions tailored to meet your unique needs. Orion Cyber is a culmination
      of passion, expertise, and dedication to excellence in the digital realm.
    </p>
  );

  const mainBody = (
    <div>
      <h2>Our Story</h2>
      <p>
        Orion Cyber was born out of a desire to empower individuals and
        businesses with innovative technology solutions that drive growth and
        success. As a sole developer and owner of the business, I bring a wealth
        of experience and a deep commitment to delivering exceptional results to
        every project I undertake.
      </p>

      <h2>Our Approach</h2>
      <p>
        At Orion Cyber, we believe in a personalised approach to digital
        solutions. We take the time to understand your goals, challenges, and
        vision to create customised solutions that exceed expectations. Whether
        you need a mobile application, a website, or graphic design services,
        we're here to bring your ideas to life.
      </p>

      <h2>Why Choose Orion Cyber?</h2>
      <ul>
        <li>
          <strong>Expertise:</strong> With years of experience in the industry,
          we have the knowledge and skills to tackle projects of any size or
          complexity.
        </li>
        <li>
          <strong>Personalised Service:</strong> As a sole developer, I am
          personally invested in the success of every project. You can expect
          dedicated support and a tailored approach to meet your unique needs.
        </li>
        <li>
          <strong>Innovation:</strong> We're passionate about staying ahead of
          the curve and embracing the latest technologies and trends to deliver
          innovative solutions that make a difference.
        </li>
        <li>
          <strong>Customer Satisfaction:</strong> Your satisfaction is our top
          priority. We work closely with you every step of the way to ensure we
          meet and exceed your expectations.
        </li>
      </ul>

      <h2>Our Commitment</h2>
      <p>
        At Orion Cyber, we are committed to delivering excellence in everything
        we do. From concept to execution, we strive to provide unparalleled
        quality, reliability, and value to our clients. Your success is our
        success, and we're dedicated to helping you achieve your goals.
      </p>

      <h2>Get in Touch</h2>
      <p>
        Ready to elevate your digital presence?{" "}
        <Link to="/Support">Contact Us</Link> today to discuss your project
        needs and let's work together to bring your vision to life.
      </p>

      <p>
        Thank you for considering Orion Cyber for your digital solutions. We
        look forward to the opportunity to collaborate with you.
      </p>
    </div>
  );

  const smallBody = (
    <div>
      <h2>Business Info</h2>
      <p>Orion Cyber Ltd{" - "}[ 15580959 ]</p>
      <h2>Useful Links</h2>

      <ul className={styles.listNoBullets}>
        <li>
          <Link to="/">Privacy Policy</Link>
        </li>
        <li>
          <Link to="/">Terms & Conditions</Link>
        </li>
        {/* <li>
                  <Link to="/FAQ">Frequently Asked Questions (FAQs)</Link>
                </li> */}
      </ul>
    </div>
  );

  return (
    <>
      <Body
        mainBody={mainBody}
        smallBody={smallBody}
        headerActiveLink="About"
        tagline={tagline}
        isFullWidth={false}
      />
    </>
  );
};

export default About;
