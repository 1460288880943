import { Link } from "react-router-dom";
// import "./NavigationBar.module.css";
import styles from "./NavigationBar.module.css";

type HeaderProps = {
  activeLink?: string;
  isMobile?: boolean;
};

const NavigationBar = ({ activeLink, isMobile }: HeaderProps) => {
  return (
    <div className={styles.menu}>
      {isMobile ? null : (
        <>
          <Link
            to="/"
            className={`${styles.menuItem} ${
              activeLink === "Home" ? styles.menuItemActive : ""
            }`}
          >
            Home
          </Link>
          <Link
            to="/about"
            className={`${styles.menuItem} ${
              activeLink === "About" ? styles.menuItemActive : ""
            }`}
          >
            About
          </Link>
          <Link
            to="/articles"
            className={`${styles.menuItem} ${
              activeLink === "Articles" ? styles.menuItemActive : ""
            }`}
          >
            Articles
          </Link>
          <Link
            to="/products"
            className={`${styles.menuItem} ${
              activeLink === "Products" ? styles.menuItemActive : ""
            }`}
          >
            Products
          </Link>
          <Link
            to="/services"
            className={`${styles.menuItem} ${
              activeLink === "Services" ? styles.menuItemActive : ""
            }`}
          >
            Services
          </Link>
          <Link
            to="/support"
            className={`${styles.menuItem} ${
              activeLink === "Support" ? styles.menuItemActive : ""
            }`}
          >
            Support
          </Link>
        </>
      )}

      <Link
        to="https://plesk.orioncyber.co.uk"
        className={styles.loginButton}
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginRight: isMobile ? "13px" : "" }}
      >
        Login
      </Link>
    </div>
  );
};

export default NavigationBar;
