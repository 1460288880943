import React, { useEffect } from "react";
import { ProductsData } from "../../../data/ProductsData";
import { Link, useParams } from "react-router-dom";
import useNavigationContext from "../../../contexts/NavigationContext";
import { IconContext } from "react-icons";
import { FaDownload } from "react-icons/fa6";
import docPrivacy from "../../../assets/docs/jengu-privacy.pdf";
import docTerms from "../../../assets/docs/jengu-terms.pdf";
import Body from "../../../layout/Body/Body";
import buttonStyles from "../../../constants/Global.module.css";
import styles from "./Product.module.css";

const Product = () => {
  const { setSideDrawerIsOpen } = useNavigationContext();
  useEffect(() => {
    setSideDrawerIsOpen(false);
  }, [setSideDrawerIsOpen]);

  const { productId } = useParams();

  const product = ProductsData.find((product) => product.id === productId);

  if (!product) {
    return (
      <>
        <Body
          tagline={<p>PRODUCT NOT FOUND</p>}
          headerActiveLink="Products"
          isFullWidth={false}
          mainBody={<p>We couldn't find the product you were looking for.</p>}
        />
      </>
    );
  }

  const mainBody = (
    <>
      <img
        src={product?.image}
        alt={product?.name}
        className={styles.productImage}
      />
      <div dangerouslySetInnerHTML={{ __html: product.description }} />
    </>
  );
  const sideBody = (
    <>
      <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
        <Link
          to={product.downloadLink}
          className={`${buttonStyles.button} ${styles.buttonProduct} ${buttonStyles.buttonAccented}`}
        >
          <FaDownload className={buttonStyles.buttonIcon} />
          Download
        </Link>
      </IconContext.Provider>
      <h2>Quick Links</h2>
      <ul style={{ listStyle: "none", paddingLeft: "10px" }}>
        <li>
          <Link to={docPrivacy} target="_blank" download>
            Privacy Policy
          </Link>
        </li>
        <li>
          <Link to={docTerms} target="_blank" download>
            Terms & Conditions
          </Link>
        </li>
      </ul>
    </>
  );
  return (
    <>
      <Body
        mainBody={mainBody}
        headerActiveLink="Products"
        smallBody={sideBody}
        isFullWidth={false}
      />
    </>
  );
};

export default Product;
